export default [
  {
    key: "date_from",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
  },
  {
    key: "date_to",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
  },
  {
    key: "status_lead",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status Lead",
    model: null,
    multiple: true,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  // {
  //   type: "select",
  //   key: "owner",
  //   margin: true,
  //   showLabel: true,
  //   label: "Owner",
  //   model: null,
  //   options: [],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 7,
  // },
  // {
  //   type: "select",
  //   key: "status_owner",
  //   margin: true,
  //   showLabel: true,
  //   label: "Status owner",
  //   model: null,
  //   options: [],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 5,
  //   emitEvent: true,
  //   typeEvent: "status_owner",
  // },
  // {
  //   type: "select",
  //   key: "assign_to",
  //   margin: true,
  //   showLabel: true,
  //   label: "Assign To",
  //   model: null,
  //   options: [],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 7,
  // },
  // {
  //   type: "select",
  //   key: "status_catcher",
  //   margin: true,
  //   showLabel: true,
  //   label: "Status catcher",
  //   model: null,
  //   options: [],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 5,
  //   emitEvent: true,
  //   typeEvent: "status_catcher",
  // },
  {
    type: "select",
    key: "cr",
    margin: true,
    showLabel: true,
    label: "CR",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
  // {
  //   type: "select",
  //   key: "programs",
  //   margin: true,
  //   showLabel: true,
  //   label: "Programs",
  //   multiple: true,
  //   model: null,
  //   options: [],
  //   reduce: "id",
  //   selectText: "label",
  //   cols: 12,
  // },
  {
    type: "select",
    key: "st/ad",
    margin: true,
    showLabel: true,
    label: "State",
    model: null,
    options: [],
    reduce: "label",
    selectText: "label",
    cols: 6,
  },
  {
    type: "select",
    key: "source_name",
    margin: true,
    showLabel: true,
    label: "Source Name",
    multiple: true,
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
  },
  // {
  //   type: "select",
  //   key: "type_doc",
  //   margin: true,
  //   showLabel: true,
  //   label: "Type Doc",
  //   model: null,
  //   options: [],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 6,
  // },
];
